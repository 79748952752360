<template>
    <v-card>
        <v-card-title>
            <h3>Users</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search Users..."
                clearable
                single-line
                hide-details
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        @click="newUserForm"
                        color="primary"
                        dark
                        fab
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New User</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <users-grid
                :keywords.sync="keywords"
                :client_id.sync="
                    this.$store.getters.userForPortalAccess.client.id
                "
                :reload.sync="reloadVar"
                @edit-user="editUser"
            />
        </v-card-text>
        <v-dialog v-model="userFormDialog" persistent max-width="600">
            <user-form
                :user.sync="formUser"
                :client_id="this.$store.getters.userForPortalAccess.client.id"
                @user-form-saved="
                    closeUserForm()
                    reloadGrid()
                "
                @user-form-closed="closeUserForm()"
            />
        </v-dialog>
    </v-card>
</template>
<script>
import UsersGrid from '@/views/user-dashboard/users/UsersGrid'
import UserForm from '@/views/user-dashboard/users/UserForm'
import { authGate } from '@/mixins/authGate'
import { mapGetters } from 'vuex'

export default {
    mixins: [authGate],
    name: 'users',
    components: {
        'users-grid': UsersGrid,
        'user-form': UserForm,
    },
    data: () => ({
        keywords: '',
        selectedUser: {},
        reloadVar: 0,
        userFormDialog: false,
        formUser: {},
    }),
    computed: {
        ...mapGetters({
            authUser: 'authUser',
        }),
    },
    methods: {
        reloadGrid: function() {
            this.reloadVar++
        },
        newUserForm: function() {
            this.formUser = {}
            this.userFormDialog = true
        },
        closeUserForm: function() {
            this.userFormDialog = false
        },
        closePassForm: function() {
            this.passFormDialog = false
        },
        editUser: function(user) {
            this.formUser = user
            this.userFormDialog = true
        },
    },
}
</script>
